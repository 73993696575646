import React from 'react'
import { DataGrid } from '@material-ui/data-grid'

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  { field: 'category', headerName: 'Category', type: 'string', flex: 1 },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 110,
    type: 'number',
    valueFormatter: params => {
      return (params.value / 100).toFixed(2)
    },
  },
  { field: 'referenceType', headerName: 'Reference Type', type: 'string', flex: 1 },
  { field: 'referenceId', headerName: 'Reference ID', type: 'string', flex: 1 },
  { field: 'market', headerName: 'Market', type: 'string', flex: 1 },
  { field: 'note', headerName: 'Note', type: 'string', flex: 1 },
  { field: 'updatedAt', headerName: 'Updated', type: 'dateTime', flex: 1 },
  { field: 'createdAt', headerName: 'Created', type: 'dateTime', flex: 1 },
]

export default function TransactionsGrid(props) {
  return (
    <div style={{ height: props.height || '80vh', width: '100%' }}>
      <DataGrid rows={props.rows} columns={columns} density="compact" loading={props.loading} />
    </div>
  )
}
